import React from "react"
import { FaPython, FaChartBar, FaDatabase } from "react-icons/fa"
const services = [
  {
    id: 1,
    icon: <FaPython className="service-icon" />,
    title: "python",
    text: `Previous experience with Pandas and GGPlot. Currently developing a web-scraping application to gather data for my RMDBS.`,
  },
  {
    id: 2,
    icon: <FaChartBar className="service-icon" />,
    title: "Data Visualization",
    text: `Extensive experience in data visualization through my professional and academic experience. Currently creating a visualization to aggregate and understand data-points with my database.`,
  },
  {
    id: 3,
    icon: <FaDatabase className="service-icon" />,
    title: "SQL",
    text: `Knowledge in database design, reporting queries, data cleaning, data extraction. Currently learning query optimization techniques`,
  },
]

export default services
